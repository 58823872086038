<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Estefania Villada Chavarria                                   ###### -->
<!-- ###### @date: Abril 2024                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <section class="subHeader-content">
    <BlueHeader :itemsHeader="itemsHeader" />

    <!-- Seccion para cargar el contenido de la navegación de las rutas hijas del submenu-->
    <v-slide-y-transition mode="out-in">
      <router-view></router-view>
    </v-slide-y-transition>

  </section>
</template>
<!-- #################################################################################### -->
<!-- ###### Sección de Script                                                      ###### -->
<!-- #################################################################################### -->
<script>
import { Role } from "@/router/role.js";
import BlueHeader from "../../../../components/BlueHeader.vue";

export default {
  name: 'PerformanceEvaluation',
  components: { BlueHeader },
  data: () => ({
    itemsHeader: [
      {
        name: 'Evaluaciones',
        link: { name: 'modules.humanTalent.performanceEvaluation.evaluaciones' },
        rol: Role.TalentoHumano_EvaluacionDesempeno_Evaluaciones,
      },
      {
        name: 'Nominaciones',
        link: { name: 'modules.humanTalent.performanceEvaluation.nominaciones' },
        rol: Role.TalentoHumano_EvaluacionDesempeno_Nominaciones,
      },
      {
        name: 'Reemplazos',
        link: { name: 'modules.humanTalent.performanceEvaluation.reemplazos' },
        rol: Role.TalentoHumano_EvaluacionDesempeno_Reemplazos,
      },
      {
        name: 'Grupo de Preguntas',
        link: { name: 'modules.humanTalent.performanceEvaluation.grupoPreguntas' }, 
        rol: Role.TalentoHumano_EvaluacionDesempeno_GrupoPreguntas,
      },
      {
        name: 'Datos',
        link: { name: 'modules.humanTalent.performanceEvaluation.datos' }, 
        rol: Role.TalentoHumano_EvaluacionDesempeno_Datos,
      },
      {
        name: 'Tipos de Evaluación',
        link: { name: 'modules.humanTalent.performanceEvaluation.tiposEvaluacion' }, 
        rol: Role.TalentoHumano_EvaluacionDesempeno_TiposEvaluacion,
      },
    ]
  }),
}
</script>
<!-- #################################################################################### -->
<!-- ###### Sección de Style                                                       ###### -->
<!-- #################################################################################### -->
<style scoped>
.subHeader-content {
  width: 96%;
}
</style>